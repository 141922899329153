// TODO нужен рекурсивный обход полей таблицы

const getFileList = (model, fields) => {
  const arr = [];
  fields.forEach((el) => {
    if (el.class === 'uploader') {
      if (model[el.id] && model[el.id].length) {
        model[el.id].forEach((file) => {
          arr.push(file);
        });
      }
    } else if (el.class === 'group' && model[el.id]) {
      arr.push(...getFileList(model[el.id], el.childs));
      if (model[el.id].addedGroups) {
        model[el.id].addedGroups.forEach((addedGroupEl) => {
          arr.push(...getFileList(addedGroupEl.value, el.childs));
        });
      }
    }
  });

  return arr;
};

export default getFileList;
