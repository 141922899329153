export const ACTION_LIST = [
  {
    value: 'approve',
    name: 'Согласование',
  },
  {
    value: 'reject',
    name: 'Отклонение',
  },
];

export const APPROVAL_PAGE_SIZE = 20;

export const APPROVAL_METHODS = {
  multiApprove: 'approveTickets',
  multiReject: 'rejectTicketsApproval',
  approve: 'approveTicket',
  reject: 'rejectTicketApproval',
};
