<template>
  <div class="modal-ticket-approval">
    <esmp-modal
      v-model="show"
      :title="approveTitle"
      :width="800"
      :ok-text="approveBtnText"
      :loading="loading"
      cancel-text="Нет, я передумал"
      class-name="modal-ticket-cancel"
      @on-ok="approveHandler"
    >
      <esmp-loader v-if="loading" fix />
      <hm-form
        v-else-if="model"
        ref="form"
        :fields="checklist"
        v-model="model"
      />
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import omit from 'lodash/omit';
import Hub from '@/plugins/event-hub';
import HmForm from '@/components/hm-form/';
import getValidChecklistFields from '@/components/hm-form/helpers/getValidChecklistFields';
import createDefaultModel from '@/components/hm-form/helpers/createDefaultModel';
import mappedFields from '@/components/hm-form/helpers/mappedFields';
import getFileList from '@/components/hm-form/helpers/getFileList';
import { APPROVAL_METHODS } from '@/constants/approval';
import getJsonTypeBlob from '@/helpers/getJsonTypeBlob';

export default {
  name: 'ModalTicketApproval',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isMulti: {
      type: Boolean,
      default: false,
    },
    isApprove: {
      type: Boolean,
      default: true,
    },
    tickets: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    HmForm,
  },
  data() {
    return {
      model: undefined,
      checklist: [],
      settings: {},
      loading: false,
      formVersionId: null,
      formType: null,
      formId: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
    approveTitle() {
      return `${this.isApprove ? 'Согласование' : 'Отклонение' } ${this.isMulti ? 'заявок' : 'заявки'}`;
    },
    approveBtnText() {
      return `Да, ${this.isApprove ? 'согласовать' : 'отклонить' } ${this.isMulti ? 'заявки' : 'заявку'}`;
    },
    ticket() {
      return this.tickets[0] || null;
    },
    sourceSystem() {
      return this.ticket.sourceSystem || this.$route?.params?.source;
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('ticket', ['setForm']),
    async approveHandler() {
      this.setLoading({ key: 'app', value: true });

      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        this.$EsmpNotify.$show('Невалидные значения', 'error');
        this.setLoading({ key: 'app', value: false });
        return;
      }

      const data = new FormData();

      if (this.isMulti) {
        const multiApprovalActionData = {};
        multiApprovalActionData.approvals = this.tickets.map((ticket) => ({
          approvalId: ticket.id,
          approvalItemId: ticket.approvalId,
          source: ticket.sourceSystem,
          approvalType: ticket.approvalType,
          serviceId: ticket.serviceId,
          comment: this.isApprove ? 'Cогласовано' : 'Отклолено',
        }));
        multiApprovalActionData.checklist = this.settings;
        multiApprovalActionData.checklist.formId = this.settings.id;
        multiApprovalActionData.checklist.checklistItems = mappedFields(this.model, this.checklist);

        data.append('multiApprovalActionData', getJsonTypeBlob(multiApprovalActionData));
        const files = getFileList(this.model, this.checklist);
        files.forEach((file) => {
          data.append('attachmentList', file, file.name);
        });

        const method = this.isApprove ? APPROVAL_METHODS.multiApprove : APPROVAL_METHODS.multiReject;

        await this.$API.approval[method](data)
          .then(() => {
            this.$EsmpNotify.$show(`${this.isApprove ? 'Cогласовано' : 'Отклолено'}`, 'success');

            this.$emit('fetch-tickets');
          })
          .catch((err) => err)
          .finally(() => {
            this.setLoading({ key: 'app', value: false });
          });
      } else {
        const approvalActionData = {
          approvalId: this.ticket?.approvalId,
          approvalTheme: this.ticket?.title,
          comment: this.isApprove ? 'Cогласовано' : 'Отклолено',
          checklist: this.settings,
        };
        approvalActionData.checklist.formId = this.settings.id;
        approvalActionData.checklist.checklistItems = mappedFields(this.model, this.checklist);

        data.append('approvalActionData', getJsonTypeBlob(approvalActionData));
        const files = getFileList(this.model, this.checklist);
        files.forEach((file) => {
          data.append('attachmentList', file, file.name);
        });

        const method = this.isApprove ? APPROVAL_METHODS.approve : APPROVAL_METHODS.reject;

        await this.$API.approval[method](data, {
          source: this.sourceSystem,
          id: this.ticket.id,
        })
          .then(() => {
            this.$EsmpNotify.$show(`Заявка ${this.isApprove ? 'согласована' : 'отклонена'}`, 'success');

            this.$emit('fetch-tickets');
          })
          .catch((err) => err)
          .finally(() => {
            this.setLoading({ key: 'app', value: false });
            this.clear();
          });
      }
    },
    getCheckList(ticket) {
      this.loading = true;

      this.$API.approval.getCheckList(ticket.serviceId, {
        approvalAction: this.isApprove ? 'approve' : 'reject',
        approvalType: ticket?.approvalType,
        source: this.sourceSystem,
        ticketId: ticket?.id,
      }).then(({ data }) => {
        this.checklist = Object.freeze(getValidChecklistFields(data.fields));
        this.model = createDefaultModel(this.checklist);
        this.settings = omit(data, ['fields']);
        this.formVersionId = data.formVersionId;
        this.formType = data.formType;
        this.formId = data.id;
      }).finally(() => {
        this.loading = false;
      });
    },
    clear() {
      this.model = undefined;
      this.checklist = [];
      this.settings = {};
    },
    getForm() {
      /**
       * Подготовка данных формы для вычисляемого поля
       * и их запись в стор
       * */
      if (this.show) {
        const form = {
          checklistItems: mappedFields(this.model, this.checklist, undefined),
          formId: this.formId,
          formVersionId: this.formVersionId,
          formType: this.formType,
        };
        this.setForm(form);
      }
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.clear();
        if (!this.isMulti) {
          this.getCheckList(this.ticket);
        } else {
          const isNotSame = this.tickets.some((ticket) => (
            this.ticket.sourceSystem !== ticket.sourceSystem
              || this.ticket.sourceSystem !== ticket.sourceSystem
          ));
          if (isNotSame) {
            // eslint-disable-next-line max-len
            this.$EsmpNotify.$show('Вы выбрали согласования с разными формами согласования, просьба согласовать заявки по одному сервису', 'error');
            this.show = false;
          } else {
            this.getCheckList(this.ticket);
          }
        }
      }
    },
  },
  created() {
    Hub.$on('get-form-data-for-calc', this.getForm);
  },
  beforeDestroy() {
    Hub.$off('get-form-data-for-calc', this.getForm);
    this.setForm(null);
  },
};
</script>

<style lang="scss" scoped>
.esmp-modal-body {
  font-size: 16px;
}
::v-deep .hm-form__element:first-child {
  margin-top: 0;
}
</style>
